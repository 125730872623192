import React from "react";
import CommonProduct from "../components/commonPage"

class AboutPage extends CommonProduct {
    constructor(props) {
        super(props)
        this.firstRender = true
    }

    render() {
        let BaseComponent = this.BaseComponentRender.bind(this)
        return (
            <BaseComponent className="page" menu="about" title="About | Edtech Index">
            <h1>About</h1>
            <p className="strong">
            Since 2012, the EdTech Index has been the first stop for educators in search of learning technology solutions. Educators can find the most complete, reliable and up-to-date information available on learning technology products, including quality indicators from trusted organizations and robust product profiles.
            </p>
            <p>
            We believe that access to information is key to making equitable decisions that improve teaching and learning. Powered by ISTE's <a href="https://www.iste.org/ltd" target="_blank">Learning Technology Directory</a>, the EdTech Index provides educators better visibility into high-quality solutions. In service to our community of students, educators, district leaders, higher education stakeholders, state decision makers and solution providers, ISTE is proud to host and support the EdTech Index as a free resource for the field.
            </p>
            <p>
            Today, it is far too challenging for schools to determine if an edtech product is of sufficient quality to be used by teachers and students. To address this issue, leading edtech organizations have pledged to work together to reduce the burden on educators and school leaders. By identifying <a href="https://iste.org/blog/easing-burden-on-schools-five-quality-indicators-for-edtech-ai-products">Five Edtech Quality Indicators</a> for evaluating effective edtech products and supporting their inclusion on the EdTech Index, these organizations have demonstrated their commitment to informed decision-making:
            </p>
            <br/>
            <div className="clear dividingLine"/>
            <div className="partners">
                <div className="partner_div"><a href="https://www.1edtech.org/" target="_blank"><img src="../images/1EdTech_About_Logo.png" alt="1EdTech"/></a>           </div>
                <div className="partner_div"><a href="https://www.cast.org/" target="_blank"><img src="../images/CAST-logo-purple.png" alt="CAST"/></a>           </div>
                <div className="partner_div"><a href="https://www.cosn.org/" target="_blank"><img src="../images/CoSN_About_Logo.png" alt="CoSN"/></a>           </div>
                <div className="partner_div"><a href="https://digitalpromise.org/" target="_blank"><img src="../images/6.DP_Logo_2020_Clr_print.jpg" alt="Digital Promise"/></a>                      </div>
                <div className="partner_div"><a href="https://www.innovateedunyc.org/" target="_blank"><img src="../images/2.InnovateEDU logo transparent background.PNG" alt="InnovateEDU"/></a>     </div>
                <div className="partner_div"><a href="https://iste.org/" target="_blank"><img src="../images/iste_logo.jpeg" alt="ISTE"/></a>                      </div>
                <div className="partner_div"><a href="https://www.setda.org/" target="_blank"><img src="../images/SETDA_About_Logo.png" alt="SETDA"/></a>                      </div>
            </div>

            <p>
              Gathering and dispersing this information is truly a collaborative effort, and we couldn't do this without the support of our funders and partners. Thank you to the following organizations who have partnered with us in our collective vision to improve effective edtech discovery and decision making:
            </p>
            <br/>
            <br/>
            <div className="partners">
                <div className="partner_div"><a href="https://ccsso.org/" target="_blank"><img src="../images/1.CCSSO_color_logo-TM.jpg" alt="Council of Chief State School Officers"/></a>           </div>
                <div className="partner_div"><a href="https://www.commonsensemedia.org/" target="_blank"><img src="../images/3.H-LOGO-Common_Sense-RGB.svg" alt="Common Sense"/></a>                  </div>
                <div className="partner_div"><a href="https://educationmarkets.org/" target="_blank"><img src="../images/7.edsolutions.jpg" alt="CEMD"/></a>                                          </div>
                <div className="partner_div"><a href="https://theatlis.org/" target="_blank"><img src="../images/ATLISLogo2023.png" alt="Association of Technology Leaders In Independent Schools"/></a></div>
                <div className="partner_div"><a href="https://www.dell.org/" target="_blank"><img src="../images/4.MSDF_logo.jpg" alt="Michael and Susan Dell Foundation"/></a>                       </div>
                <div className="partner_div"><a href="https://www.gatesfoundation.org/" target="_blank"><img src="../images/9.gates_foundation.png" alt="Bill and Melinda Gates Foundation"/></a>     </div>
            </div>

            <div className="clear"/>
            <div className="disclaimer">*Our partners do not endorse any specific product listed in the Index.</div>
            <br/>
            <div className="clear"/>
            </BaseComponent>
        )
    }
}
export default AboutPage